<template>
  <div class="container-fliud">
    <Header></Header>
<div class="faqInfo">

  <h4 class="faq" style="color:#97c149">eLibrary</h4>
<div class="gray">
<div class="container" id="padding:50px">
<div class="form-group">
    <small class="alert alert-danger" style="display:none; padding:10px; width:300px" role="alert" id="alert">Field is empty</small>
</div>
  <form class="form-inline" style="margin-top:5px">
    <div class="input-group mb-2 mr-sm-2" >
    <div class="input-group-prepend">
        <select @change="sort($event)">
            <option value="">Sort by</option>
            <option value="Publication">publication</option>
            <option value="book">book</option>
            <option value="article">article</option>
            <option value="document">document</option>
        </select>
        </div>
        <input type="text" class="form-control" v-model="search" id="inputSearch" placeholder="Enter keyword">
        <div class="input-group-prepend">
        <button class="btn btn-primary" @click.prevent="searchBar()">Search <span class="spinner-border text-light" id="spinner" style="width:15px; height:15px; display:none" role="status"></span></button>
        </div>
    </div>
    </form>
</div>
</div>
<div class="container">
<div class="book">
<div class="row" id="row" v-for="pdf in elibaries" :key="pdf.id">
            <div class="col-md-4">
             
            <iframe  :src='elibrayUrl+pdf.fileName' style="width:250px; height:300px" frameborder="0" height="100%" width="100%">
</iframe>
          <!-- <img :src='elibrayUrl+pdf.fileName' alt=""  style="width:250px; height:300px;" > -->
            </div>
            <div class="col-md-6" style="margin-top:20px">
            <h3>{{pdf.title}}</h3>
            <p id="bookInfo">{{pdf.description}}</p>
            <div class="row">
            <div class="col-md-4">
                <small>Posted: {{pdf.createdAt}}</small>
            </div>
            <div class="col-md-4">
                <small>Created by: {{pdf.author}}</small>
            </div>
             <div class="col-md-4">
                <small>Country: {{pdf.country}}</small>
            </div>
            </div>
            </div>
            <div class="col-md-2">
                <a  style="margin-top:50px;"
                    :href="elibrayUrl+pdf.fileName" 
                    class="btn btn-primary" id="download" 
                    target="_blank"
                    >Download</a>
            </div>
</div>

</div>
</div>

</div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Navbar';
import Footer from '../components/footer'
import baseUrl from '../baseUrl';
import appConfig from "../appConfig";
// import PDF from 'pdfvuer'
import $ from 'jquery'

export default {
  name: 'Home',
  components: {
    Header,
   Footer,
    // PDF
  },
  data() {
      return {
          elibaries:[],
          elibrayUrl:appConfig.baseUrl,
          search:"",
          error:""
      }
  },
  methods: {
    sort(id){
         baseUrl.get('/api/elibrary/getDocuments')
          .then((res)=>{
              res.data.map(el=>{
                if(el.tag == id.target.value){
                  this.elibaries.push(el)
                }
              })
            

          })
          .catch((err)=>{
              this.error = err
          })
    },
      searchBar(){
        if(this.search == ""){
          $('#alert').show()
          return false
        }
        $('#spinner').show()
        baseUrl.post('/api/elibrary/search', this.search)
        .then((res)=>{
          this.elibaries = res.data
          $('#spinner').hide()
        }).catch((err)=>{
          this.error = err
           $('#spinner').hide()
        })
      }
  },
  mounted(){
     baseUrl.get('/api/elibrary/getDocuments')
          .then((res)=>{
              res.data.map(el=>{
                if(el.fileName.includes(".docx")){
                  el.fileName = el.fileName.replace(".docx", ".pdf")
                }
              })
              this.elibaries = res.data
          })
          .catch((err)=>{
              this.error = err
          })
  }


}
</script>
<style scoped>
.faqInfo{
margin-top: 160px;
}
#accordion{
padding: 100px;
}
.faq{
padding-left: 100px;
margin: 20px;
}
/* .hr{
padding-left: 100px;
} */
.gray{
background-color: #F5F7FA;
padding: 60px;
}
.gray button{
background-color: #97c149;
border: #97c149;
padding: 15px;
}
#inputSearch{
width: 500px;
padding: 26px;
}
select{
width: 200px;
padding: 16px;
}
.scrum{
display: flex;
}
p{
margin: 5px;
font-size: bolder;
}
.book{
padding: 70px;
border: 1px solid rgb(204, 204, 204);
margin: 50px;
}
#bookInfo{
color: rgb(146, 146, 146);
}
.img{
width: 250px;
height: 200px;
object-fit: contain;
}
#checkbox{
font-size: 35px;
padding: 20px;
}
#download{
background-color: rgb(255, 255, 255);
border: 1px solid rgb(167, 164, 164);
color: rgb(167, 164, 164);
}
#row{
background-color: #F5F7FA;
padding: 10px;
margin-bottom: 20px;
}
</style>